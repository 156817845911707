import React from "react"
// import { Link } from "gatsby"
import { 
  Link, 
} from "gatsby-theme-material-ui";
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Layout from "../layout/layout"
import SEO from "../seo/seo"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 50
  },
  blogImage: {
    width: "100%",

    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 300
    // }
  },
  btnReadMore: {
    marginTop: 20, 
    width: 200, 
    height: 45
  },
  blogContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, /* number of lines to show */
    WebkitBoxOrient: 'vertical',
  }
}));

const Blog = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query BlogQuery {
      allContentfulBlog (sort: {order: DESC, fields: createdAt}) {
        edges {
          node {
            blogTitle
            blogContent {
              blogContent
            }
            slug
            createdAt (formatString:"MMMM Do, YYYY")
            blogImage {
              fluid (maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              # description
            }
            blogPostedAt (formatString:"MMMM Do, YYYY")
            blogAuthor {
              authorName
              # authorTitle
            } 
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  
  return (
    <Layout>
      <SEO 
        title={`Blog | ${data.site.siteMetadata.title}`}
      />
        <Container 
          wrap="wrap" 
          maxWidth="md" 
          className={classes.root}
        >
          <Box
            mb={3}
          >
            <Typography 
              variant="h2"
            >
              Blogs
            </Typography>
          </Box>
          <Grid 
            container 
            direction="row" 
            spacing={3}
          >
            {
              data.allContentfulBlog.edges.map(item => (
                <Grid 
                  item 
                  container 
                  key={item.node.blogTitle} 
                  spacing={3}
                >
                  <Grid 
                    item 
                    md={6} 
                    sm={6} 
                    xl={6} 
                    xs={12}
                  >
                    {
                      item.node.blogImage && (
                        <Img 
                          fluid={item.node.blogImage.fluid} 
                          alt={item.node.blogImage.title} 
                          className={classes.blogImage} 
                        />
                      )
                    }
                  </Grid>
                  <Grid 
                    item 
                    md={6} 
                    sm={6} 
                    xl={6} 
                    xs={12}
                  >
                    <Link 
                      to={"/blog/" + item.node.slug} 
                      color="textPrimary"
                    >
                      <Typography 
                        variant="subtitle1" 
                        component="p"
                      >
                        {item.node.blogTitle}
                      </Typography>
                    </Link>      
                    <Typography 
                      variant="body1" 
                      className={classes.blogContent}
                    >
                      {item.node.blogContent.blogContent}
                    </Typography>
                    <Box 
                      mt={3} 
                      display="flex" 
                      alignItems="center"
                    >
                      {
                        item.node.blogAuthor && (
                          <>
                            <Box 
                              display="flex" 
                              alignItems="center" 
                              mr={5}
                            >
                              <AccessTimeIcon 
                                fontSize="small" 
                              />
                              <Typography 
                                display="inline" 
                                variant="body2"
                              >
                                {!item.node.blogPostedAt ? item.node.createdAt : item.node.blogPostedAt}
                              </Typography>
                            </Box>
                            <Typography 
                              display="inline" 
                              variant="body2"
                            >
                              {item.node.blogAuthor.authorName}
                            </Typography>
                          </>
                        )
                      }
                      
                    </Box>
                    <Button 
                      fullWidth={false} 
                      variant="contained" 
                      color="primary" 
                      disableElevation 
                      size="large" 
                      href={`/blog/${item.node.slug}`} 
                      className={classes.btnReadMore}
                    >
                      Read More
                    </Button>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Container>
    </Layout>
  )
}

export default Blog